import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '../button'
import styles from './donateCard.module.css'

class DonateCard extends Component {
  static propTypes = {
    donationAmount: PropTypes.string.isRequired,
    description: PropTypes.string,
    buttonText: PropTypes.string,
  }

  render() {
    const { donationAmount, description, buttonText, valueID } = this.props
    return (
      <div className={styles.donateCard}>
        <div className={styles.donateCardInner}>
          <h1>{donationAmount}</h1>
          <p>{description}</p>
          <form
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            target="_top"
          >
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value={valueID} />
            <Button type="submit">{buttonText ? buttonText : 'Donate'}</Button>
          </form>
        </div>
      </div>
    )
  }
}

export default DonateCard
