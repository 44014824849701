import React from 'react'
import Layout from '../components/layout/'
import Callout from '../components/callout/'
import PageWrapper from '../components/pageWrapper'
import Header from '../components/header'
import DonateCard from '../components/donateCard'
import styles from './styles/donate.module.css'

const Donate = ({ data }) => {
  const stats = data.stats.edges[0].node

  return (
    <Layout callout={<Callout />}>
      <Header stats={stats} />
      <PageWrapper>
        <div className={styles.contentBlock}>
          <h1>Give the Gift of Books</h1>
          <p>
            We need your financial contributions to support this program in
            Grand Junction. Together we can ensure every child has the
            opportunity to succeed. <br />
            <br />
            The cost of each book, including postage, is only slightly more than
            <strong> $2.00</strong>, so...
          </p>
        </div>
        <section className={styles.donateCards}>
          <DonateCard
            donationAmount="$25"
            buttonText="Donate $25"
            description="12 books, supporting 1 child for 1 year "
            valueID="2HKHWL42C69Q4"
          />
          <DonateCard
            donationAmount="$125"
            buttonText="Donate $125"
            description="60 books, supporting 1 child for the full 5 years of books"
            valueID="S327D8SXUYKFE"
          />
          <DonateCard
            donationAmount="$250"
            buttonText="Donate $250"
            description="120 books, supporting 2 children for the full 5 years of books"
            valueID="9GY3YU8NZ6UTS"
          />
          <DonateCard
            donationAmount="$500"
            buttonText="Donate $500"
            description="240 books, supporting 4 children for the full 5 years of books"
            valueID="8BWK347MU8W64"
          />
          <DonateCard
            donationAmount="$1,000"
            buttonText="Donate $1,000"
            description="480 books, supporting 8 children for the full 5 years of books"
            valueID="HBHZNECU5FXL4"
          />
          <DonateCard
            donationAmount="Custom"
            description="Choose your own donation amount."
            valueID="VV44MV8AWYZCS"
          />
        </section>
      </PageWrapper>
    </Layout>
  )
}

export const donatePageQuery = graphql`
  query donatePageQuery {
    stats: allContentfulStats {
      edges {
        node {
          id
          booksSent
          kidsRegistered
        }
      }
    }
  }
`

export default Donate
